/**
 * set and get the app cookies
 */

function setCookie (cname, cvalue, exdays) {
  const date = new Date()
  date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
  // it doesn't work on local, maybe needed for live system after pen test, not sure
  // document.cookie = `${cname}=${cvalue};${expires};path=/; Secure; HttpOnly; SameSite`
}

function getCookie (cname) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let x = 0; x < ca.length; x++) {
    var c = ca[x]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

/**
 * set all cookies function
 * set the selected cookies function
 */

function setAllCookie () {
  setCookie('preferencescookie', 'preferences_set', 365)
  setCookie('functionalcookie', 'acceptance', 365)
  setCookie('analyticscookie', 'acceptance', 365)
}

function saveCookiesPreferences () {
/**
 * set the preferencescookie
 */

  setCookie('preferencescookie', 'preferences_set', 365)

  /**
   * check if functionalcookie is checked / set the cookie
   * check if functionalcookie is unchecked but the cookie is present / remove the cookie
   */

  if ($('#functionalcookie').prop('checked')) {
    setCookie('functionalcookie', 'acceptance', 365)
  }
  if ($('#functionalcookie').prop('checked') === false && getCookie('functionalcookie') === 'acceptance') {
    document.cookie = 'functionalcookie=""; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  /**
   * check if analyticscookie is checked / set the cookie
   * check if analyticscookie is unchecked but the cookie is present / remove the cookie
   */

  if ($('#analyticscookie').prop('checked')) {
    setCookie('analyticscookie', 'acceptance', 365)
  }
  if ($('#analyticscookie').prop('checked') === false && getCookie('analyticscookie') === 'acceptance') {
    document.cookie = 'analyticscookie=""; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
}


function intializeCookie () {
  /**
   * acceptallcookie click event
   */

  $('.acceptallcookie').on('click', function () {
    /**
     * hide the modal
     * set all cookies
     * reload the page
     */

    $('#privacysettings').modal('hide')
    setAllCookie()
    location.reload()
  })

  /**
   * managecookie click event
   */

  $('.managecookie').on('click', function () {
    /**
     * prevent #essentialcookie to be unchecked
     * restore #essentialcookie to checked if unchecked
     */

    $('#essentialcookie').on('click', function (e) {
      e.preventDefault()
    })

    if ($('#essentialcookie').prop('checked') === false) {
      $('#essentialcookie').prop('checked', 'checked')
    }

    /**
     * check the presence of functioncookie and alanyticscookie
     * if present check the correspondent checkbox
     */

    if (getCookie('functionalcookie')) {
      $('#functionalcookie').prop('checked', 'checked')
    }

    if (getCookie('analyticscookie')) {
      $('#analyticscookie').prop('checked', 'checked')
    }

    /**
     * hide the .cookieexplication
     * hide #privacysettings .modal-footer
     * show .cookiepreferences
     */

    $('.cookieexplication').addClass('d-none')
    $('#privacysettings .modal-footer').addClass('d-none')
    $('.cookiepreferences').removeClass('d-none')
  })

  /**
   * savecookie click event
   */

  $('.savecookie').on('click', () => {
    /**
     * set the correct cookie
     * hide the modal
     */

    saveCookiesPreferences()
    $('#privacysettings').modal('hide')

    /**
     * reload the page
     */
    location.reload()
  })

  /**
   * check if the preferencescookie is not set
   * show the cookie modal
   * disable the escape evente at first loading
   */

  if (!getCookie('preferencescookie')) {
    $('#privacysettings').modal({
      backdrop: 'static',
      keyboard: false
    })
  }

  /**
   *  - show the help modal
   *  - recreate the modal on dismiss and enable the escape event again
   * Note the `one` binding
   * restore the modal to its first view
   */

  $('#privacysettings').one('hidden.bs.modal', () => {
    $('#privacysettings').modal('dispose')
    $('.cookieexplication').removeClass('d-none')
    $('.cookiepreferences').addClass('d-none')
    $('#privacysettings .modal-footer').removeClass('d-none')
  })

/**
 * end intializeCookie
 */
}

/**
 * EXPORT
 */

export default {
  intializeCookie
}
